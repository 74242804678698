<template>
  <div class="divider"></div>
</template>

<script>
export default {
  name: 'Divider'
}
</script>

<style scoped lang="less">
.divider {
  height: 1px;
  background-color: #DFE4EB;
}
</style>

<template>
  <van-tab :title="title" v-bind="$attrs">
    <div class="tab-content">
     <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
       <card
             :title="item.title"
             v-for="(item, index) in taskList"
             :key="index"
             class="task-item"
             @click="handleNavTo(item)"
       >
         <div class="content space-between">
           <img
               :src="item.img || require('@/assets/img/default-img.png')"
               alt=""
           />
           <div class="info-wrapper">
             <div class="info-item space-between">
               <div class="label">验货时间</div>
               <div class="value">{{ item.startDate }}</div>
             </div>
             <div class="info-item space-between">
               <div class="label">执行地点</div>
               <div class="value">{{ item.companyArea }}{{item.companyDetailAdress}}</div>
             </div>
             <div class="info-item space-between">
               <div class="label">验货类型</div>
               <div class="value">{{ item.yanhuoType_dictText }}</div>
             </div>
             <div class="info-item space-between">
               <div class="label">验货款数</div>
               <div class="value">{{ item.itemModelTotal }}</div>
             </div>
             <div class="info-item space-between">
               <div class="label">抽检总数</div>
               <div class="value">{{ item.sampleTotal }}</div>
             </div>
           </div>
         </div>
       </card>
     </van-list>
    </div>
  </van-tab>
</template>

<script>
import { taskStatus } from "@/constants";
import Card from "@/components/Common/Card.vue";
import { getAction } from "@/api/manage";
import inobounce from 'inobounce';

export default {
  name: "TaskTab",
  components: { Card },
  props: {
    title: {
      type: String,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      finished: false,
      taskList: [],
      queryParams: {
        pageNo: 0,
        pageSize: 10,
      },
    };
  },
  created() {
    this.getList()
  },
  mounted() {
    let u = navigator.userAgent
    if (u.indexOf('iPhone') > -1) {
      inobounce.enable()
    }
  },
  beforeDestroy() {
    inobounce.disable()
  },
  methods: {
    handleNavTo(value) {
      if(value.taskStatus === 'CANCELLED'){
        this.Toast('该任务已取消')
        return
      }
      this.$router.push({
        name: "taskDetail",
        query: {
          ...this.$route.query,
          taskId: value.id,
          active: 'basic'
        }
      });
    },
    onLoad() {
      this.getList();
    },
    // 获取政策法规列表数据
    getList() {
      this.loading = true;
      this.finished = false;
      if(this.queryParams.pageNo > 10) return
      this.queryParams.pageNo = ++this.queryParams.pageNo;
      let params = Object.assign(this.queryParams, { taskStatus: this.status });
      getAction('/jeecg-system/admin/taskBaseInfo/list', params).then((res) => {
        if (res.success) {
          let records = res.result.records.map(item => {
            item.title = `${item.companyName}-${item.yanhuoType_dictText}-${item.taskNo}`
            return item
          })
          this.taskList = [...this.taskList, ...records];
          this.loading = false;
          if (this.taskList.length >= res.result.total) {
            this.finished = true;
          }
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.tab-content {
  .task-item {
    margin-top: 16px;
    background-color: #fff;
    border-radius: 8px;

    .content {
      img {
        width: 80px;
        height: 80px;
        flex-shrink: 0;
      }

      .info-wrapper {
        width: calc(100% - 96px);

        .info-item {
          margin-bottom: 8px;

          &:last-of-type {
            margin-bottom: 0;
          }

          .label {
            width: 50px;
            font-size: 12px;
            color: rgba(#000000, 0.4);
          }

          .value {
            width: 138px;
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
    }
  }
}
</style>

<template>
  <div class="card" @click="$emit('click')">
    <div class="title space-between vertical-center">
      <span v-if="title">{{ title }}</span>
      <slot name="title"/>
    </div>
    <divider v-if="showDivider"/>
    <div class="card-content">
      <slot/>
    </div>
  </div>
</template>

<script>
import Divider from '@/components/Common/Divider.vue'

export default {
  name: 'Card',
  components: {Divider},
  props: {
    title: {
      type: String,
      default: ''
    },
    showDivider: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped lang="less">
.card {
  margin-top: 16px;
  padding: 0 16px;
  background-color: #fff;
  border-radius: 8px;

  .title {
    padding: 12px 0;
    font-size: 16px;
    font-weight: 500;
    word-break: break-all;
    line-height: 24px;
    color: #4B4E51;
  }

  .card-content {
    padding: 16px 0;
  }
}
</style>

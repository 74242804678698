<template>
  <div class="index-page">
    <!-- 验货员页面 -->
    <div v-if="userRoleCodes!='supplier'">
      <global-header :is-show-back="false" title="验货任务" />
      <tab-page>
        <task-tab
          title="待验货"
          name="wait_inspect"
          :status="taskStatus.WAIT_INSPECT"
        />
        <task-tab
          title="进行中"
          name="processing"
          :status="taskStatus.TASK_PROCESSING"
        />
        <task-tab
          title="已完成"
          name="finished"
          :status="taskStatus.TASK_FINISHED"
        />
        <task-tab title="全部" name="all" :status="taskStatus.TASK_ALL" />
      </tab-page>
    </div>
    <!-- 供应商页面 -->
     <div v-if="userRoleCodes==='supplier'">
      <global-header :is-show-back="false" title="验货任务" />
      <tab-page>
        <gonghuo-task-tab
          title="进行中"
          name="processing"
          :status="taskStatus.TASK_PROCESSING"
        />
        <gonghuo-task-tab
          title="已完成"
          name="finished"
          :status="taskStatus.TASK_FINISHED"
        />
          <gonghuo-task-tab
          title="全部"
          name="all"
          :status="taskStatus.TASK_ALL"
        />
        <!-- <task-tab title="全部" name="all" :status="taskStatus.TASK_ALL" /> -->
      </tab-page>
    </div>
  </div>
</template>

<script>
import { taskStatus } from "@/constants";
import GlobalHeader from "@/components/Common/GlobalHeader.vue";
import TaskTab from "@/components/Index/TaskTab.vue";
import GonghuoTaskTab from "@/components/Index/GonghuoTaskTab"
import TabPage from "@/components/Common/TabPage.vue";
import { getAction } from "@/api/manage";

export default {
  name: "index",
  components: { TabPage, TaskTab, GlobalHeader,GonghuoTaskTab },
  data() {
    return {
      userKey: this.$route.query.user_key,
      userRoleCodes:sessionStorage.userRoleCodes,
      taskStatus,
      showPopup: false,
    };
  },
  created() {
    
    // this.getUserInfo()
    // getAction('/jeecg-system/sys/loginByUserId',{
    //       userId:this.userKey
    // }).then(res=>{
    //   console.log(this.userKey);
    // })
  },
  mounted () {
  },
  methods: {
    // async getUserInfo() {
    //   const result = await getAction("/sys/api/getUserById", {
    //     id: "1556845207805235201",
    //   });
    //   console.log(result);
    // },
  },
};
</script>

<style scoped>
</style>

<template>
  <div class="global-header">
    <div class="placeholder"></div>
    <div class="header center">
      <img v-show="isShowBack" class="arrow" src="~@/assets/svg/icon-arrow-left.svg" alt="" @click="handleClickBack">
      <div class="title">{{ title }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GlobalHeader",
  props: {
    title: {
      type: String,
      required: true
    },
    backFunc: {
      type: Function,
      default: null
    },
    isShowBack: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    handleClickBack() {
      this.backFunc ? this.backFunc() : this.$router.go(-1)
    }
  }
}
</script>

<style scoped lang="less">
.global-header {
  width: 100vw;
  height: 44px;
  position: relative;
  .placeholder {
    width: 100%;
    height: 100%;
  }

  .header {
    width: 100vw;
    height: 44px;
    position: absolute;
    top: 0;
    left: 0;

    .arrow {
      width: 22px;
      height: 22px;
      padding: 6px;
      position: absolute;
      top: 8px;
      left: 6px;
    }

    .title {
      font-size: 18px;
      font-weight: 500;
    }

    .placeholder {
      width: 22px;
      height: 22px;
    }
  }
}
</style>
